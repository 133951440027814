<template>
  <div>
    <div class="background">
      <div class="headLink">
        <div class="head" :style="{ width: maxWitch + '%' }">
          <img class="headImg" src="../img/headline.png" alt="headImg" />
          <div class="headLater">
            <img src="../img/headlineOne.png" alt="headLater" />
          </div>
          <div class="headEnd">
            <img src="../img/headlineTwo.png" alt="headEnd" />
          </div>
          <div class="headButton">
            <div class="butt">
              <a @click="event_1">
                <img src="../img/android.png" alt="android" />
              </a>
              <a @click="event_2">
                <img src="../img/ios.png" alt="ios" />
              </a>
            </div>
            <div class="xzbz">
              <a
                href="https://www.3839.com/a/155310.htm?from=hykb"
                target="_blank"
              >
                <img src="../img/xzbz.png" alt="xzbz"
              /></a>
              <a href="https://tap.cn/mDhkBl3O?channel=rep-rep_phcdba709jl" target="_blank">
                <img src="../img/taptap.png" alt="taptap"
              /></a>
            </div>
          </div>
          <div class="welfare">
            <img class="welfareImg" src="../img/welfare1.png" alt="welfare" />
          </div>
          <div class="feature">
            <img src="../img/feature.png" alt="featureImg" />
          </div>
          <div class="elementImg">
            <div class="block">
              <el-carousel
                :style="{ width: widthImg + 'px', height: heightImg + 'px' }"
              >
                <el-carousel-item v-for="item in item" :key="item.name">
                  <img
                    :src="item.imgUrl"
                    alt=""
                    :style="{
                      width: widthImg + 'px',
                      height: heightImg + 'px',
                    }"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>

          <div class="endVideo alternative">
            <div class="pv" ref="pv">
              <img src="../img/videoPV.png" alt="" @click="videoPlay($event)" />
            </div>
            <div class="cg" ref="pv">
              <img src="../img/videoCG.png" alt="" @click="videoPlay($event)" />
            </div>

            <div class="videoPv" v-show="play">
              <video-pv-vue :title="title" v-on:displayShow="displayShow" />
            </div>
          </div>

          <div class="end">
            <div class="endTitle">
              <ul>
                <li>
                  <h6>
                    出版单位：杭州渡口网络科技有限公司
                    批准文号：国新出审[2022]1039号 ISBN 978-7-498-09680-7
                  </h6>
                </li>

                <li>
                  <h6>
                    运营单位：合肥常春藤移动科技有限公司&emsp;
                    著作权人：成都光子狐科技有限公司
                  </h6>
                </li>
                <li>
                  <h6>
                    蜀ICP备2022000525号&emsp; 
                    <a href="https://beian.miit.gov.cn/" target="_blank"
                      >蜀ICP备2022000525号-2A</a
                    >
                  </h6>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/css/app.css";
import videoPvVue from "./videoPv.vue";
// import TrackingIO from "trackingIO_h5_sdk_2.0.0"

export default {
  name: "dataImg",
  components: { videoPvVue },

  data() {
    return {
      androidUrl:
        "https://apps.bytesfield.com/download/basic/cur/5c98a6c34591ebf7d423ef8ca98c61374241f891",
      iosUrl: "https://apps.apple.com/cn/app/id6446662347",
      welfareUrl: "/",
      item: [
        { name: 1, imgUrl: require("../img/feature/riceFlower.png") },
        { name: 2, imgUrl: require("../img/feature/loveDog.png") },
        { name: 3, imgUrl: require("../img/feature/gameMachine.png") },
        { name: 4, imgUrl: require("../img/feature/designDream.png") },
        { name: 5, imgUrl: require("../img/feature/aroundWord.png") },
      ],
      screeWidth: document.body.clientWidth,
      maxWitch: 50,
      widthMax: 1,
      title: "",
      play: false,
      pops: false,
    };
  },
  computed: {
    widthImg() {
      return this.screeWidth * 0.76;
    },
    heightImg() {
      return this.widthImg / 0.59;
    },
    videoWidth() {
      return document.body.clientWidth * (this.maxWitch / 200);
    },
  },
  methods: {
    // open() {
    //   this.$alert("这是一段内容", "标题名称", {
    //     confirmButtonText: "确定",
    //     callback: (action) => {
    //       this.$message({
    //         type: "info",
    //         message: `action: ${action}`,
    //       });
    //     },
    //   });
    // },
    event_1() {
      //安卓
      window.TrackingIO.event("event_1");
      window.open(this.androidUrl, "_blank");
    },
    event_2() {
      //ios
      window.TrackingIO.event("event_2");
      window.open(this.iosUrl, "_blank");
    },

    getWelfare() {
      this.pops = true;
    },
    changeWidth() {
      setInterval(() => {
        setTimeout(() => {
          if (document.body.clientWidth <= 414) {
            this.maxWitch = 100;
            this.widthMax = 1;
          } else {
            this.maxWitch = 50;
            this.widthMax = 0.5;
          }
          return (this.screeWidth = document.body.clientWidth * this.widthMax);
        }, 350);
      }, 400);
    },
    videoPlay(event) {
      var videoClick = event.currentTarget.parentElement.className;

      this.play = !this.play;
      if (videoClick == "pv") {
        this.title = "pv";
      } else {
        this.title = "cg";
      }
      console.log("title", this.title);
    },
    displayShow(data) {
      if (data == 1) {
        this.play = false;
        this.title == "";
      }
    },
  },
  mounted() {
    this.changeWidth();
    // window.TrackingIO.init("ea1a7d36b856b5826e1bf957485c78ab");
  },
  watch: {},
};
</script>

<style scoped>
@import "../css/app.css";
</style>