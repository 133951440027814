<template>
  <div class="video" :key="new Date().getTime()">
    <video
      class="cg"
      muted:true
      loop:false
      controls
      autoplay:true
      @ended="endVideo"
    >
      <source :src="srcs" type="video/mp4" />
    </video>
    <!-- <video v-show="pvs" class="cg" muted:true loop controls autoplay:true>
      <source :src="srcPv" type="video/mp4" />
    </video> -->
    <!-- <div class="deleVideo">
      <span >x</span>
    </div> -->
    <div class="headTitle">
      
        <button class="bu" type="button" @click="Videos">感谢观看</button>
      
    </div>
  </div>
</template>

<script>
import "@/css/video.css";
export default {
  name: "videoPv",
  props: ["title"],
  data() {
    return {
      srcPv: "https://imgsrc.bergekrish.com/magicabin/PV.mp4",
      srcCg: "https://imgsrc.bergekrish.com/magicabin/CG_ZH.mp4",

      videoTitle: "下一条",
      VideoList: 0,
      srcs: [],
      pvs: false,
      cgs: false,
    };
  },
  computed: {
    httpSrc() {
      //   if (this.title == "pv") {
      //     return this.srcPv;
      //   } else {
      //     return this.srcCg;
      //   }
      //   return this.title == "pv" ? "this.srcPv" : "this.srcCg";
      return this.pv;
    },
  },
  methods: {
    butt() {
      if (this.title == "pv") {
        this.title == "cg";
      } else {
        this.title == "pv";
      }
    },
    pv(title) {
      if (title == "pv") {
        // this.pvs = !this.pvs;
        this.srcs = this.srcPv;
      } else {
        // this.cgs = !this.cgs;
        this.srcs = this.srcCg;
      }
    },
    endVideo() {},
    Videos() {
      
      this.$emit("displayShow", this.VideoList);
      this.VideoList = 1;
      console.log(this.VideoList)
    },
  },
  mounted() {
    this.pv(this.title);

    this.$nextTick(() => {
      this.pv();
    });
  },
  watch: {
    title(val) {
      this.pv(val);
    },
  },
};
</script>
<style scoped>
.video {
  position: relative;
}
.video .headTitle {
  position: relative;
  height: 20px;
}
.headTitle .bu {
  width: 100%;
  position: absolute;
  margin: 0 auto;
  height: 50px;
}
.bu button {
  margin: 0 auto;
}
.video .deleVideo {
  position: absolute;
  top: 1%;
  bottom: 0;

  left: 50px;
}
.deleVideo span {
  font-size: 25px;

  opacity: 0.6;
}
button {
  background: rgba(0, 0, 0, 0);
  border: none;
}
button::after {
  border: none;
}
span {
  color: white;
}
</style>
