var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"background"},[_c('div',{staticClass:"headLink"},[_c('div',{staticClass:"head",style:({ width: _vm.maxWitch + '%' })},[_c('img',{staticClass:"headImg",attrs:{"src":require("../img/headline.png"),"alt":"headImg"}}),_vm._m(0),_vm._m(1),_c('div',{staticClass:"headButton"},[_c('div',{staticClass:"butt"},[_c('a',{on:{"click":_vm.event_1}},[_c('img',{attrs:{"src":require("../img/android.png"),"alt":"android"}})]),_c('a',{on:{"click":_vm.event_2}},[_c('img',{attrs:{"src":require("../img/ios.png"),"alt":"ios"}})])]),_vm._m(2)]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"elementImg"},[_c('div',{staticClass:"block"},[_c('el-carousel',{style:({ width: _vm.widthImg + 'px', height: _vm.heightImg + 'px' })},_vm._l((_vm.item),function(item){return _c('el-carousel-item',{key:item.name},[_c('img',{style:({
                    width: _vm.widthImg + 'px',
                    height: _vm.heightImg + 'px',
                  }),attrs:{"src":item.imgUrl,"alt":""}})])}),1)],1)]),_c('div',{staticClass:"endVideo alternative"},[_c('div',{ref:"pv",staticClass:"pv"},[_c('img',{attrs:{"src":require("../img/videoPV.png"),"alt":""},on:{"click":function($event){return _vm.videoPlay($event)}}})]),_c('div',{ref:"pv",staticClass:"cg"},[_c('img',{attrs:{"src":require("../img/videoCG.png"),"alt":""},on:{"click":function($event){return _vm.videoPlay($event)}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.play),expression:"play"}],staticClass:"videoPv"},[_c('video-pv-vue',{attrs:{"title":_vm.title},on:{"displayShow":_vm.displayShow}})],1)]),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headLater"},[_c('img',{attrs:{"src":require("../img/headlineOne.png"),"alt":"headLater"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headEnd"},[_c('img',{attrs:{"src":require("../img/headlineTwo.png"),"alt":"headEnd"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xzbz"},[_c('a',{attrs:{"href":"https://www.3839.com/a/155310.htm?from=hykb","target":"_blank"}},[_c('img',{attrs:{"src":require("../img/xzbz.png"),"alt":"xzbz"}})]),_c('a',{attrs:{"href":"https://tap.cn/mDhkBl3O?channel=rep-rep_phcdba709jl","target":"_blank"}},[_c('img',{attrs:{"src":require("../img/taptap.png"),"alt":"taptap"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"welfare"},[_c('img',{staticClass:"welfareImg",attrs:{"src":require("../img/welfare1.png"),"alt":"welfare"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feature"},[_c('img',{attrs:{"src":require("../img/feature.png"),"alt":"featureImg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"end"},[_c('div',{staticClass:"endTitle"},[_c('ul',[_c('li',[_c('h6',[_vm._v(" 出版单位：杭州渡口网络科技有限公司 批准文号：国新出审[2022]1039号 ISBN 978-7-498-09680-7 ")])]),_c('li',[_c('h6',[_vm._v(" 运营单位：合肥常春藤移动科技有限公司  著作权人：成都光子狐科技有限公司 ")])]),_c('li',[_c('h6',[_vm._v(" 蜀ICP备2022000525号  "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("蜀ICP备2022000525号-2A")])])])])])])
}]

export { render, staticRenderFns }