<template>
  <dataimg />

</template>

<script>
import dataimg from "./components/dataImg.vue";

import './trackingIO_h5_sdk_2.0.0'

export default {
  name: "APP",
  data() {
    return {};
  },
  components: {
    dataimg,

  },
};
</script>
<style scoped>

</style>