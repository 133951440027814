import Vue from 'vue'
import App from './App.vue'
import Video from 'video.js'
import { Carousel, CarouselItem, Image } from 'element-ui'
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'

// import '../ellLog'


// import 'vue-directive-image-previewer/dist/assets/style.css'


import 'lib-flexible'
import 'amfe-flexible'

import  '@/trackingIO_h5_sdk_2.0.0'


Vue.use(VueDirectiveImagePreviewer)

Vue.config.productionTip = false

Vue.prototype.$video = Video
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Image)


new Vue({
  render: h => h(App),
}).$mount('#app')
